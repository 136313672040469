.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  position: relative;
  justify-content: flex-start; /* Move content towards the top */
}

.header {
  margin-top: 20px; /* Adjust as needed for spacing */
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.account-info {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px; /* Add some space below the form */
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:disabled {
  background-color: #cccccc;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content h3 {
  margin-bottom: 15px;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.link {
  display: inline-block;
  margin-top: 10px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 15px;

}


.modalForget {
  position: fixed;
  z-index: 9999;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 80%; /* Adjust the width to 80% of the viewport */
  max-width: 600px; /* Set a maximum width */
}

.modal-contentForget {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width of the modal */
}

.modal-contentForget input {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%; /* Make input fields take up full width of the modal content */
}

.modal-contentForget button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Full width for buttons */
}

.modal-contentForget button:hover {
  background-color: #0056b3;
}

.auth-containerForget button.link-button {
  margin-top: 10px;
  background: none;
  color: blue;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}